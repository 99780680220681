import React, { useState, useEffect } from 'react'
import { mediaQuery, capitalize } from '@cms/utils'

import { Translate } from 'gatsby-plugin-translate'

import * as S from './styled'
import getIcon from '../Icons'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { useProductDetailContext } from '../../../contexts'

const Prepare = () => {
  const { productDetail: product } = useProductDetailContext()

  const [expandedAccordion, setExpandedAccordion] = useState(true)

  const preExpandedAccordions = mediaQuery.isMedium() ? [] : ['preparo']

  const dataCurrentService = {
    exame: 'Agendamento de Exames | Exame',
    vacina: 'Agendamento de Vacinas | Vacina',
  }

  useEffect(() => {
    setExpandedAccordion(!preExpandedAccordions.length ? false : true)
  }, [])

  return (
    <Accordion
      allowZeroExpanded="true"
      preExpanded={preExpandedAccordions}
      onChange={() => setExpandedAccordion(expandedAccordion ? false : true)}
    >
      <AccordionItem
        uuid="preparo"
        data-item-text={`${expandedAccordion ? 'Fechar' : 'Abrir'} Preparo`}
        data-item-position="Informações de preparo"
        data-current-service={dataCurrentService[product.type]}
      >
        <AccordionItemHeading>
          <S.AccordionItemButtonCustom className="accordion-title">
            <Translate>Informações de preparo</Translate>
          </S.AccordionItemButtonCustom>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <S.PrepareContainer>
            {product.preparation &&
              product.preparation.map((prep, index) => {
                if (
                  prep.descricao &&
                  !/Não se aplica/i.test(prep.descricao) &&
                  !/Not applicable/i.test(prep.descricao) &&
                  prep.descricao.trim() !== ''
                ) {
                  return (
                    <S.PrepareItemContainer key={index}>
                      <S.PrepareTypeIcon>{getIcon(prep.tipoPreparo.codigoApi)}</S.PrepareTypeIcon>
                      <S.PrepareTypeTitle>{capitalize(prep.tipoPreparo.nome)}</S.PrepareTypeTitle>
                      <S.PrepareTypeInfo>{prep.descricao}</S.PrepareTypeInfo>
                    </S.PrepareItemContainer>
                  )
                }
                return null
              })}
          </S.PrepareContainer>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default Prepare
