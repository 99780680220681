import React from 'react'

const InformacaoJejumIcon = () => {
  return (
    <svg
      className="primary-fill-color"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="23"
      height="32"
      viewBox="0 0 23 32"
    >
      <defs>
        <path
          id="rowajy9v0a"
          d="M16.328 0c3.266 0 6.56 8.067 6.658 17.637v.014c.014.013.014.013.014.026 0 .919-.785 1.65-1.738 1.65h-2.453c-.378 0-.7.307-.687.68v10.649c0 .745-.616 1.33-1.387 1.344-.785 0-1.402-.586-1.402-1.318V1.065l.006-.11c.057-.506.496-.888 1.046-.888h.014zm-7.11 0c.728 0 1.324.585 1.324 1.33v6.667c0 2.262-1.417 4.27-3.523 5.03h.013c-.265.092-.437.345-.437.638v16.991c0 .745-.583 1.33-1.31 1.344-.743 0-1.325-.585-1.325-1.317V13.718c0-.293-.186-.532-.437-.625l-.216-.083C1.321 12.195 0 10.248 0 8.077l.013.013V1.437c0-.745.583-1.33 1.325-1.33.728 0 1.324.585 1.324 1.33V8.09c0 .692.278 1.357.768 1.863l.013.013c.066.066.146.093.239.106.185.014.33-.146.344-.319V1.49c0-.745.583-1.33 1.324-1.33.729 0 1.325.585 1.325 1.33v8.29c0 .093.04.173.106.24.132.119.344.119.476 0v.012c.49-.492.768-1.157.768-1.849V1.53l.007-.138C8.1.714 8.654.2 9.35.2h.013z"
        />
        <path id="1o860p13kc" d="M0 0H120.75V118.588H0z" />
      </defs>
      <g>
        <g>
          <g transform="translate(-115 -758) translate(115 758)">
            <mask id="551ucocpub">
              <use xlinkHref="#rowajy9v0a" />
            </mask>
            <use xlinkHref="#rowajy9v0a" />
            <g mask="url(#551ucocpub)">
              <g transform="translate(-48.875 -43.294)">
                <mask id="uiqc8mq5jd">
                  <use xlinkHref="#1o860p13kc" />
                </mask>
                <use xlinkHref="#1o860p13kc" />
                <path d="M-61.333 -60.235H182.084V178.824H-61.333z" mask="url(#uiqc8mq5jd)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default InformacaoJejumIcon
