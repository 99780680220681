import React from 'react'

const AtividadeFisicaIcon = () => {
  return (
    <svg
      className="primary-fill-color"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path
          id="q4ocyl38sa"
          d="M27.979 8.136c1.04-.418 2.217.159 2.61 1.283l.77 2.248-.149-.189c.771 2.234-.04 4.742-1.92 6.01v.014c-.81.533-1.744.793-2.677.778H23.22c-.365 0-.676.332-.662.735v10.809c0 1.196-.906 2.162-2.015 2.176-1.123 0-2.028-.966-2.028-2.147l-.014-12.64c0-.2.081-.403.23-.532v.014l2.623-2.464c.122-.116.284-.188.46-.173l.013.001h5.044c.081 0 .162-.014.243-.043.352-.144.528-.562.406-.922l.014.014-.758-2.234c-.378-1.11.163-2.334 1.204-2.738zM20.91 4v.001h5.442c1.174 0 2.148.93 2.148 2.08 0 1.137-.974 2.08-2.148 2.08h.014-5.414c-.158 0-.33.056-.444.167v.014l-1.905 1.525 1.605 2.126c.244.277.2.693-.071.943v.014l-2.12 1.861c-.014.014-.029.028-.029.042-.315.236-.773.18-1.002-.11l.014.013-1.862-2.315-1.045.832c-.889.72-2.02 1.095-3.166 1.109v.001H2.648C1.46 14.383.5 13.44.5 12.303c0-1.15.96-2.079 2.148-2.079h8.308c.157 0 .315-.055.458-.152h.014l6.303-4.977C18.619 4.375 19.75 4 20.91 4zM10 0c1.933 0 3.5 1.567 3.5 3.5S11.933 7 10 7 6.5 5.433 6.5 3.5 8.067 0 10 0z"
        />
        <path id="qu73yk8yhc" d="M0 0H126V126H0z" />
      </defs>
      <g>
        <g>
          <g>
            <g transform="translate(-526 -141) translate(526 141) translate(.5)">
              <mask id="734fjdjhrb">
                <use xlinkHref="#q4ocyl38sa" />
              </mask>
              <use xlinkHref="#q4ocyl38sa" />
              <g mask="url(#734fjdjhrb)">
                <g transform="translate(-38 -50)">
                  <mask id="ykpwjbm0nd">
                    <use xlinkHref="#qu73yk8yhc" />
                  </mask>
                  <use xlinkHref="#qu73yk8yhc" />
                  <path d="M-64 -64H190V190H-64z" mask="url(#ykpwjbm0nd)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AtividadeFisicaIcon
