import React from 'react'

const PrazoIcon = () => {
  return (
    <svg
      className="primary-fill-color"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path
          id="a1vs8mlkfa"
          d="M15.927.767c8.61.013 15.596 6.999 15.61 15.623l-.004.314c-.168 8.467-7.1 15.283-15.606 15.296C7.302 32 .317 25.002.317 16.39.317 7.766 7.302.78 15.927.78h.013zm0 2.6c-7.194 0-13.008 5.815-13.008 13.009 0 7.193 5.814 13.008 13.008 13.021l.013.001-.026-.027c7.18-.013 12.995-5.828 13.008-13.008l-.003-.295c-.151-7.05-5.915-12.7-12.992-12.7zm-.65 4.555c.715 0 1.3.572 1.3 1.3v6.596l6.036 5.528-.04-.065c.533.495.572 1.314.09 1.847v.013c-.494.534-1.313.56-1.834.091l-6.45-5.905c-.274-.247-.43-.586-.417-.95h.013V9.223c0-.729.573-1.301 1.301-1.301z"
        />
        <path id="in4sx176uc" d="M0 0H168V168H0z" />
      </defs>
      <g>
        <g>
          <g>
            <g transform="translate(-115 -660) translate(115 660) translate(.45)">
              <mask id="929phzd62b">
                <use xlinkHref="#a1vs8mlkfa" />
              </mask>
              <use xlinkHref="#a1vs8mlkfa" />
              <g mask="url(#929phzd62b)">
                <g transform="translate(-54.333 -68)">
                  <mask id="f7043o8s3d">
                    <use xlinkHref="#in4sx176uc" />
                  </mask>
                  <use xlinkHref="#in4sx176uc" />
                  <path
                    d="M-85.333 -85.333H253.33399999999997V253.33399999999997H-85.333z"
                    mask="url(#f7043o8s3d)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PrazoIcon
