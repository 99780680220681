import styled from 'styled-components'
import media from 'styled-media-query'

export const ProductDetailContainer = styled.div`
  margin: 4.8rem 9.6rem;
  width: inherit;
  max-width: inherit;
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
  display: grid;
  grid-template-columns: auto 33.6rem;
  gap: 14.4rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin: 0 2.4rem;
    max-width: calc(100% - 4rem);
    margin-bottom: 3rem;
    margin-top: 3rem;
    grid-template-columns: 1fr;
    gap: 0;
  `}
`
