import React from 'react'

import * as S from './styled'

import DetailList from './DetailList'
import Unit from './Unit'

const ProductDetail = () => {
  return (
    <S.ProductDetailContainer>
      <DetailList />
      <Unit />
    </S.ProductDetailContainer>
  )
}

export default ProductDetail
