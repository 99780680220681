import React, { useState } from 'react'
import { Translate } from 'gatsby-plugin-translate'
import * as S from './styled'

import { useProductDetailContext } from '../../contexts'

const Unit = () => {
  const [unitState, setUnitState] = useState('SP')

  const { settings } = useProductDetailContext()

  const handleUnitLIstFilteredPerState = e => {
    const unitsFiltered = e.target.value
    setUnitState(unitsFiltered)
  }

  const handleNavLink = originalLink => {
    if (typeof window !== 'undefined') {
      const isVacinasPath = window.location.pathname.includes('vacinas');
      if (isVacinasPath) {
        return originalLink.replace('/agd-exames/', '/vacinas/');
      }
    }
    return originalLink;
  };

  return (
    <S.Aside>
      <S.UnitsContainer>
        <S.UnitsListTitle>
          <Translate>Selecione a unidade desejada</Translate>:
        </S.UnitsListTitle>

        <>
          <S.StatesContainer>
            {['SP', 'RJ'].map((state, index) => (
              <>
                <S.StatesInputRadio
                  type="radio"
                  id={state}
                  name="stateSelected"
                  value={state}
                  onChange={handleUnitLIstFilteredPerState}
                  defaultChecked={index === 0}
                />
                <S.StatesInputLabel htmlFor={state}>{state}</S.StatesInputLabel>
              </>
            ))}
          </S.StatesContainer>

          {unitState === 'RJ' ? (
            <S.ScheduleButton
              href={handleNavLink(settings.scheduling_link_rj)}
              target={
                handleNavLink(settings.scheduling_link_rj).match(/^https?:\/\//g) ? '_blank' : null
              }
            >
              <Translate>Agendar</Translate>
            </S.ScheduleButton>
          ) : (
            <S.ScheduleButton
              href={handleNavLink(settings.scheduling_link_sp)}
              target={
                handleNavLink(settings.scheduling_link_sp).match(/^https?:\/\//g) ? '_blank' : null
              }
            >
              <Translate>Agendar</Translate>
            </S.ScheduleButton>
          )}
        </>
      </S.UnitsContainer>
    </S.Aside>
  )
}
export default Unit
