import React from 'react'

const RestricaoIdadeIcon = () => {
  return (
    <svg
      className="primary-fill-color"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path
          id="56wpbs38za"
          d="M16.68 0c8.827 0 16 7.16 16 16h-.013c-.014 8.827-7.174 15.987-16 16h.006l-.308-.003C7.775 31.831.849 24.892.683 16.315L.68 16c0-8.84 7.16-16 16-16zM6.573 7.773c-.146-.106-.36-.08-.466.067v.013c-1.8 2.334-2.787 5.2-2.774 8.16 0 7.36 5.974 13.32 13.334 13.32h.013l-.067-.053c2.947 0 5.814-.973 8.147-2.773h.013c.014-.014.027-.027.04-.027.12-.133.12-.347 0-.467zM16.587 2.56c-2.934 0-5.8.973-8.12 2.787h.013c-.013.013-.027.026-.027.04-.133.133-.133.346 0 .48l.014.013 18.226 18.239.04.04c.147.106.36.08.48-.054v.014c1.8-2.334 2.787-5.2 2.787-8.147 0-7.36-5.973-13.32-13.32-13.307v.002z"
        />
        <path id="xiqmyn2nnc" d="M0 0H252V252H0z" />
      </defs>
      <g>
        <g>
          <g>
            <g transform="translate(-712 -141) translate(711 141) translate(.32)">
              <mask id="w6zlar5e1b">
                <use xlinkHref="#56wpbs38za" />
              </mask>
              <use xlinkHref="#56wpbs38za" />
              <g mask="url(#w6zlar5e1b)">
                <g transform="translate(-96 -88)">
                  <mask id="taq5ht1o8d">
                    <use xlinkHref="#xiqmyn2nnc" />
                  </mask>
                  <use xlinkHref="#xiqmyn2nnc" />
                  <path d="M-128 -128H380V380H-128z" mask="url(#taq5ht1o8d)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default RestricaoIdadeIcon
