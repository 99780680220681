import React from 'react'

const DefaultIcon = () => {
  return (
    <svg
      className="primary-fill-color"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path
          id="glsjbtj1la"
          d="M22.367 4c1.392 0 2.541 1.057 2.628 2.407L25 6.57v21.86c0 1.359-1.095 2.48-2.467 2.565l-.166.005H2.633C1.228 31 .091 29.943.005 28.605L0 28.443V6.596l.005-.163C.087 5.139 1.135 4.114 2.46 4.03l.174-.005H6.28c.15 0 .289.108.322.256l.007.065v1.94c0 .147-.121.283-.266.315l-.063.007H3.33c-.322 0-.602.244-.64.559l-.004.096v20.562l.007.096c.04.278.259.494.542.547l.109.012h14.231c.137 0 .274-.04.39-.117l.084-.063v.013l4.226-4.112c.095-.093.164-.218.188-.35l.01-.1.013.013V7.328c0-.315-.25-.588-.563-.625l-.096-.004h-2.949c-.158 0-.287-.104-.32-.239l-.009-.07V4.463l.007-.066c.026-.126.126-.224.255-.25l.067-.006h3.634L22.354 4h.013zM11.625 20.848c.54 0 .987.424.987.964 0 .489-.386.9-.873.957l-.114.006H6.7c-.553 0-.987-.437-.987-.963 0-.502.374-.903.87-.958l.117-.006h4.924zm6.937-4.511c.502 0 .923.366.981.85l.007.114-.027-.001c0 .489-.374.89-.86.954l-.114.01H6.701c-.553 0-.987-.436-.987-.963 0-.501.374-.903.87-.958l.117-.006h11.861zm-.026-4.485c.54 0 .987.424.987.964 0 .49-.386.9-.873.957l-.114.007H6.701c-.553 0-.987-.437-.987-.964 0-.501.374-.903.87-.958l.117-.006h11.835zM12.986 0c2.138 0 3.888 1.545 4.008 3.507l.006.191V6.77c0 .634-.526 1.16-1.193 1.222L15.67 8l.013.001H10.37l-.04-.05-.137-.007c-.633-.057-1.122-.51-1.185-1.085L9 6.733v-2.96C8.975 2.141 10.08.692 11.724.168l.2-.058v.012C12.269.037 12.628 0 12.987 0zm.028 2.459c-.506 0-.932.36-.98.824l-.004.11-.04-.037v.012c0 .468.39.861.892.915L13 4.29h.014c.544 0 .996-.417.996-.921s-.452-.922-.996-.91z"
        />
        <path id="s7usx4k73c" d="M0 0H126V126H0z" />
      </defs>
      <g>
        <g>
          <g>
            <g transform="translate(-342 -141) translate(342 141) translate(4 .833)">
              <mask id="og60est0ob">
                <use xlinkHref="#glsjbtj1la" />
              </mask>
              <use xlinkHref="#glsjbtj1la" />
              <g mask="url(#og60est0ob)">
                <g transform="translate(-44 -52)">
                  <mask id="762ehpgxbd">
                    <use xlinkHref="#s7usx4k73c" />
                  </mask>
                  <use xlinkHref="#s7usx4k73c" />
                  <path d="M-64 -64H190V190H-64z" mask="url(#762ehpgxbd)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DefaultIcon
