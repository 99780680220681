import React from 'react'

const HorarioColetaIcon = () => {
  return (
    <svg
      className="primary-fill-color"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path
          id="5054u3ve5a"
          d="M1.492 19c.83 0 1.493.57 1.508 1.296v9.395c0 .726-.663 1.296-1.492 1.309C.663 31 0 30.43 0 29.717v-9.382l.007-.127c.072-.664.706-1.17 1.5-1.17h.016zm8.542 1c1.123 0 2.144.708 2.598 1.816l.013.013c.076.174.1.347.114.534 0 .748-.555 1.335-1.249 1.349v.001H8.332c-.441 0-.782.374-.782.841 0 .467.353.828.795.841h11.52l-.126-.081c.693 0 1.261.587 1.261 1.335v.36c0 1.643-1.261 2.978-2.812 2.991H4.63C4.277 30 4 29.706 4 29.346v-8.678l.007-.1c.044-.325.303-.568.624-.568zM8.828 3.73c5.102-4.973 13.361-4.973 18.463 0l.013.012-.132-.114c5.102 4.972 5.102 13.023.013 17.995v.013c-.496.483-1.305.483-1.8.013-.522-.484-.535-1.298-.027-1.794h.014c2.596-2.53 3.64-6.193 2.753-9.652-1.41-5.456-7.072-8.775-12.657-7.402C9.872 4.175 6.467 9.694 7.876 15.15l.013.013c.143.661-.261 1.31-.914 1.5-.691.191-1.409-.203-1.604-.877l.013.013c-1.098-4.337.194-8.916 3.431-12.07zM18.336 5c.734 0 1.335.55 1.335 1.252v6.257h4.007l-.014-.026c.735.013 1.336.563 1.336 1.264 0 .689-.601 1.252-1.336 1.252l.014.001h-5.342C17.588 15 17 14.45 17 13.761v-7.51c0-.7.588-1.251 1.336-1.251z"
        />
        <path id="gqg34phtlc" d="M0 0H126V126H0z" />
      </defs>
      <g>
        <g>
          <g>
            <g transform="translate(-903 -141) translate(903 141) translate(.234 .667)">
              <mask id="r7evmo1a7b">
                <use xlinkHref="#5054u3ve5a" />
              </mask>
              <use xlinkHref="#5054u3ve5a" />
              <g mask="url(#r7evmo1a7b)">
                <g transform="translate(-39 -36)">
                  <mask id="9o28ynagqd">
                    <use xlinkHref="#gqg34phtlc" />
                  </mask>
                  <use xlinkHref="#gqg34phtlc" />
                  <path d="M-64 -64H190V190H-64z" mask="url(#9o28ynagqd)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HorarioColetaIcon
