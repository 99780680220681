import React from 'react'

const CoberturaAnsIcon = () => {
  return (
    <svg
      className="primary-fill-color"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path
          id="4narelbhqa"
          d="M18.107 5.119c.733.013 1.333.6 1.333 1.346 0 .734-.6 1.334-1.333 1.334l.013.001h-15c-.173 0-.333.16-.32.347v20.672l.007.067c.03.154.163.268.326.28h20.68c.174 0 .334-.16.334-.334V16.499l.006-.13c.063-.684.624-1.204 1.327-1.204h.013l-.093-.074h.013c.734 0 1.334.586 1.334 1.333v12.68c0 1.467-1.2 2.667-2.667 2.667h.013H2.76c-1.48 0-2.667-1.186-2.667-2.652V7.785l.006-.17C.185 6.216 1.337 5.12 2.76 5.12zM28.865.773c.574-.706 1.627-.826 2.347-.24L31.12.427l.004.004.01.009c.72.573.826 1.627.253 2.347V2.8L14.72 23.467c-.04.026-.067.066-.093.106-.654.667-1.72.68-2.374.04l.008.008L5.6 16.96l-.106-.12c-.532-.656-.496-1.624.12-2.227.653-.64 1.706-.64 2.36.014l5.36 5.36L28.851.773z"
        />
        <path id="f18nkvcm1c" d="M0 0H168V168H0z" />
      </defs>
      <g>
        <g>
          <g transform="translate(-115 -562) translate(115 562)">
            <mask id="dpa0ruf9db">
              <use xlinkHref="#4narelbhqa" />
            </mask>
            <use xlinkHref="#4narelbhqa" />
            <g mask="url(#dpa0ruf9db)">
              <g transform="translate(-49.333 -68)">
                <mask id="ik7zv63ced">
                  <use xlinkHref="#f18nkvcm1c" />
                </mask>
                <use xlinkHref="#f18nkvcm1c" />
                <path
                  d="M-85.333 -85.333H253.33399999999997V253.33399999999997H-85.333z"
                  mask="url(#ik7zv63ced)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CoberturaAnsIcon
