import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import ProductDetail from '../components/ProductDetail'
import { ProductDetailProvider } from '../components/contexts'
import Layout from '../components/layout'
import SEO from '../components/seo'

const formatTextFromWP = (text, productName) => {
  return text.replace(/\[nome_do_produto\]/g, productName)
}

const Product = ({ data, location, pageContext }) => {
  const { products: product, settings, examSettings, vaccineSettings } = data

  const productSettings = product.type === 'exame' ? examSettings.acf : vaccineSettings.acf

  const title = productSettings.title_details
    ? formatTextFromWP(productSettings.title_details, product.name)
    : `${product.name} | ${settings.brand_name}`
  const description = productSettings.description_details
    ? formatTextFromWP(productSettings.description_details, product.name)
    : `Orientações gerais sobre o produto ${product.name}`

  return (
    <Layout pageContext={pageContext} location={location}>
      <SEO
        title={title}
        description={description}
        canonical={`${settings.brand_url}/${product.type}s/${product.slug}/`}
      />
      <ProductDetailProvider
        productState={{
          productDetail: product,
          settings,
          examSettings,
          vaccineSettings,
        }}
      >
        <ProductDetail />
      </ProductDetailProvider>
    </Layout>
  )
}

Product.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default Product

export const unitQuery = graphql`
  query($productIdentifier: Int!) {
    products(productIdentifier: { eq: $productIdentifier }) {
      id
      name
      synonymies
      productIdentifier
      preparation {
        descricao
        nomeFaixaEtaria
        nomePerfil
        tipoPreparo {
          codigoApi
          nome
          tipoValor
        }
      }
      scheduling
      ansCover
      type
      slug
      deadline {
        prazoHora
        prazoHoraUtil
      }
    }
    site {
      id
      siteMetadata {
        title
        subtitle
      }
    }
    settings: wordpressAltaThemeSettings {
      brand_name
      brand_url
      phone_df
      phone_rj
      phone_sp
      scheduling_link_df
      scheduling_link_rj
      scheduling_link_sp
      home_sched_link_rj
      home_sched_link_sp
    }
    examSettings: wordpressWpGatsbyPage(slug: { eq: "exames" }) {
      acf {
        product_budget
        title_details
        description_details
      }
    }
    vaccineSettings: wordpressWpGatsbyPage(slug: { eq: "vacinas" }) {
      acf {
        product_budget
        title_details
        description_details
      }
    }
  }
`
