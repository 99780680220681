import React from 'react'

import AtividadeFisicaIcon from '../assets/icons/AtividadeFisicaIcon'
import DefaultIcon from '../assets/icons/DefaultIcon'
import InformacaoAgendamentoIcon from '../assets/icons/InformacaoAgendamentoIcon'
import InformacaoJejumIcon from '../assets/icons/InformacaoJejumIcon'
import RestricaoIdadeIcon from '../assets/icons/RestricaoIdadeIcon'
import HorarioColetaIcon from '../assets/icons/HorarioColetaIcon'
import PrazoIcon from '../assets/icons/PrazoIcon'
import CoberturaAnsIcon from '../assets/icons/CoberturaAnsIcon'
import NaoCoberturaAnsIcon from '../assets/icons/NaoCoberturaAnsIcon'

const getIcon = type => {
  const info = {
    atividade_fisica: () => <AtividadeFisicaIcon />,
    default: () => <DefaultIcon />,
    informacao_agendamento: () => <InformacaoAgendamentoIcon />,
    informacao_jejum: () => <InformacaoJejumIcon />,
    jejum_maximo: () => <InformacaoJejumIcon />,
    jejum_minimo: () => <InformacaoJejumIcon />,
    restricao_idade: () => <RestricaoIdadeIcon />,
    horario_coleta: () => <HorarioColetaIcon />,
    prazo: () => <PrazoIcon />,
    cobertura_ans: () => <CoberturaAnsIcon />,
    nao_cobertura_ans: () => <NaoCoberturaAnsIcon />,
  }
  return (info[type] || info.default)()
}

export default getIcon
