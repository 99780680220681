import React from 'react'

const InformacaoJejumIcon = () => {
  return (
    <svg
      className="primary-fill-color"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path
          id="zgo9phj04a"
          d="M23.347 0c.733.013 1.333.6 1.333 1.347 0 .733-.6 1.333-1.333 1.333l-5.32.001h-15c-.174 0-.334.16-.32.347V23.7c0 .187.146.333.333.347h20.68c.173 0 .333-.16.333-.334V6.047c0-.747.322-1.5 1.294-1.5.838 0 1.274.5 1.306 1.5v17.938c0 1.467-1.2 2.667-2.666 2.667l.013.001H2.667C1.187 26.653 0 25.467 0 24V2.667C0 1.187 1.187 0 2.667 0zM7.207 7.174c.625-.662 1.68-.679 2.338-.033l3.196 3.211.016.017c.313.314.84.314 1.17 0v.016c2.163-2.152 3.25-3.228 3.26-3.228.66-.629 1.697-.612 2.34.05.626.662.61 1.705-.033 2.35L16.3 12.787c-.313.314-.313.844.017 1.175l3.211 3.229c.626.629.626 1.655.017 2.3-.626.663-1.68.68-2.322.05l-3.194-3.21c-.313-.314-.84-.314-1.153.017h.016L9.48 19.323c-.016.017-.033.034-.033.05-.658.63-1.696.613-2.322-.033-.626-.662-.61-1.705.05-2.334v.016l3.193-3.21c.313-.314.313-.844 0-1.159l.005.006-3.182-3.2c-.574-.593-.607-1.537-.088-2.17z"
        />
        <path id="mf66g5qywc" d="M0 0H168V168H0z" />
      </defs>
      <g>
        <g>
          <g>
            <g transform="translate(-524 -330) translate(524 330) translate(2.667 2.667)">
              <mask id="jaexuxziwb">
                <use xlinkHref="#zgo9phj04a" />
              </mask>
              <use xlinkHref="#zgo9phj04a" />
              <g mask="url(#jaexuxziwb)">
                <g transform="translate(-52 -70.667)">
                  <mask id="w9at3fu94d">
                    <use xlinkHref="#mf66g5qywc" />
                  </mask>
                  <use xlinkHref="#mf66g5qywc" />
                  <path
                    d="M-85.333 -85.333H253.33399999999997V253.33399999999997H-85.333z"
                    mask="url(#w9at3fu94d)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default InformacaoJejumIcon
